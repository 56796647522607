
import LazyHydrate from "vue-lazy-hydration";
import useEvents from "~/mixins/useEvents";

export default {
  name: "BlocksContainer",
  components: {
    LazyHydrate,
  },
  mixins: [useEvents],
  props: {
    components: {
      type: Array,
      required: true,
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    showCollectionsFilters: {
      type: Boolean,
      default: false,
    },
    showCategoriesFilters: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.observerOnMounted();
  },
  beforeDestroy() {
    this.observerOnBeforeUnmount();
  },
};
